import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import { darken } from 'polished'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import yellow from '@material-ui/core/colors/yellow';
import { Layout, Navigation, Article, Wrapper, SectionTitle, Media, } from '../components'
import Logo from '../assets/img/logo.svg'
import Ripple from '../assets/img/ripple.svg'
import '../assets/type/fonts.css'

const Content = styled.div`
  grid-column: 2;
  margin-top: -1.5rem;
  padding: 3rem 6rem;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  overflow: hidden;

  img, svg {
    max-width: 100%;
  }
`

const Hero = styled.div`
  grid-column: 2;
  position: relative;
  z-index: 3;
  padding: 2rem 1rem 1rem;
  background: ${props => props.theme.colors.primary};

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 0.5rem;
  }

  .logo {
    text-align: center;

    svg {
      @media (max-width: ${props => props.theme.breakpoints.phone}) {
        width: 330px;
        margin: auto;
      }

      text {
        font-family: 'dehangsterregular', cursive;
      }
    }
  }

  .hero-home {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0;

    @media (min-width: ${props => props.theme.breakpoints.phone}) {
      padding: 3rem 0 0 0;
    }

    .hero-image {
      width: 80%;

      .tricia {
        margin: 1.25rem 1.25rem 2.5rem;
        border: 7px solid whitesmoke;
        border-radius: 500px;
        box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);

        @media (max-width: ${props => props.theme.breakpoints.tablet}) {
          margin: 1rem 1rem 2.5rem;
        }

        img {
          max-width: 100%;
        }
      }
      @media (min-width: ${props => props.theme.breakpoints.phone}) {
        width: 35%;
      }
    }
    .hero-content {
      width: 100%;
      text-align: center;
      color: white;
      padding-left: 0;

      @media (min-width: ${props => props.theme.breakpoints.tablet}) {
        padding-left: 2rem;
      }

      .ebook {
        .free {
          margin: 0 auto 0.5rem;
          padding: 1px 5px 3px;
          color: ${props => props.theme.colors.primary};
          font-size: 0.84rem;
          font-weight: 700;
          line-height: 1;
          background-color: white;
          display: inline-block;
          border-radius: 2px;
        }
        .question {
          margin: 0 0 1.5rem;
          font-size: 1.5rem;
          line-height: 1.33;
          font-weight: 400;

          &:first-child {
            margin-bottom: 2rem;
          }
        }
        .how,
      }
      @media (min-width: ${props => props.theme.breakpoints.phone}) {
        width: 65%;
      }
    }
    @media (min-width: ${props => props.theme.breakpoints.phone}) {
      flex-direction: row;
    }
  }

  .access {
    color: ${props => props.theme.colors.white};
    font-size: 1.15rem;
    line-height: 1.25;
    text-align: center;
    margin-bottom: 0;
  }

  .signup {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;

    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      flex-direction: column;
      margin-bottom: 2rem;
      padding: 0 2rem;

      div {
        width: 100%;
      }
      button {
        width: 100%;
        margin-top: 0.5rem;
      }
    }

    div {
      margin-right: 0.5rem;
    }
    button {
      margin-top: 0.25rem;
    }
    .input div input {
      color: ${props => props.theme.colors.white};
      border-color: ${props => props.theme.colors.white};
    }
    fieldset {
      border-color: ${props => props.theme.colors.white};

      &:hover, &:active {
        border-color: ${props => props.theme.colors.white} !important;
      }
    }
    label {
      color: ${props => props.theme.colors.white};
    }
    .MuiOutlinedInput-root-23.MuiOutlinedInput-focused-24 .MuiOutlinedInput-notchedOutline-30, .MuiOutlinedInput-root-23:hover:not(.MuiOutlinedInput-disabled-25):not(.MuiOutlinedInput-focused-24):not(.MuiOutlinedInput-error-28) .MuiOutlinedInput-notchedOutline-30 {
      border-color: ${props => props.theme.colors.white};

      &:hover {
        border-color: ${props => props.theme.colors.white};
      }
    }
  }

  p {

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    }
    @media (max-width: ${props => props.theme.breakpoints.phone}) {
    }
  }
`

const Response = styled.div`
  height: 200px;
  max-width: 700px;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.breakpoints.phone}) {
    height: 100px;
    margin: 0 auto 1rem auto;
  }

  .message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    color: ${props => props.theme.colors.grey.default};
    text-align: center;
    background: ${props => props.theme.colors.white};
    border-radius: 16px;
    box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
`

const Blog = styled.div`
  padding: 3rem 0 5rem 0;
  background: url('/img/asanoha.png') left top repeat;
  border-top: 1px solid ${darken(0.1, '#F5F5F5')};
  border-bottom: 1px solid ${darken(0.1, '#F5F5F5')};
`

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: yellow[700],
    },
  },
}))(Button);

const IndexPage = ({
  data: {
    allMdx: { edges: postEdges },
  },
}) => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [subscribe, setSubscribe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [subscriber, setSubscriber] = useState(false);
  const [failure, setFailure] = useState(false);

  const mcFreeEbook = (ev) => {
    setSubscribe(!subscribe)
    setLoading(true)

    let postObj = {
      email_address: email,
      status: "subscribed",
      tags: ["Main"],
      merge_fields: {
        "FNAME": firstName
      }
    };

    // fetch('http://localhost:9000/mailchimp', {
    fetch('https://selfdevlab.com/.netlify/functions/mailchimp', {
      method: 'POST',
      body: JSON.stringify(postObj),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => res.json())
      .then(response => {
        console.log(response)
        if (response.status === 400 && response.title === "Member Exists") {
          setLoading(false)
          setSubscriber(!subscriber)
          console.log('Success:', JSON.stringify(response))
        } else {
          setLoading(false)
          setSuccess(!success)
        }
      })
      .catch(error => {
        setLoading(false)
        setFailure(!failure)
        console.error('Error:', error)
      });
  }

  return (
    <Layout>
      <Navigation />
      <Hero>
        <div className="logo">
          <Logo width="400" height="68" />
        </div>
        <div className="hero-home">
          <div className="hero-image">
            <Zoom in={true} style={{ transitionDelay: '300ms' }}>
              <div className="tricia">
                <img src="/img/tricia-andor.png" alt="Tricia Andor" />
              </div>
            </Zoom>
          </div>
          <div className="hero-content">
            <Fade in={true} style={{ transitionDelay: '400ms' }}>
              <div className="ebook">
                <p className="question">Most people-pleasers try so hard to be nice and generous to others, that it’s hard to say "no".</p>
                <p className="question">I help smart, caring people-pleasers learn how to say "no" without guilt so they can get the time, energy, and self-esteem they deserve.</p>
              </div>
            </Fade>
          </div>
        </div>
        <Fade in={true} style={{ transitionDelay: '500ms' }}>
          <div>
            <p className="access"> Get your free ebook, <em>How to Say "No" Without Being a Selfish Jerk</em></p>
            <Response>
              {subscribe && <div>
                <form className="signup">
                  <TextField
                    className="input"
                    label="First Name"
                    type="test"
                    name="firstName"
                    margin="dense"
                    variant="outlined"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                  />
                  <TextField
                    className="input"
                    label="Email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    margin="dense"
                    variant="outlined"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <ColorButton variant="contained" size="large" onClick={mcFreeEbook}>
                    Get It Now!
                  </ColorButton>
                </form>
              </div> }
              {loading && <Ripple width="100" height="100" />}
              {success && <div className="message"><p>Congratulations! Click <a href="/downloads/How_to_say_no_wo_feeling_like_jerk.pdf" filename="How_to_Say_No_Withoug_Feeling_Like_Jerk" download>this link</a> for the ebook.</p></div>}
              {subscriber && <div className="message"><p>Congratulations! You already subscribed some time in the past. If you need the download link again, <a href="/downloads/How_to_say_no_wo_feeling_like_jerk.pdf" filename="How_to_Say_No_Withoug_Feeling_Like_Jerk" download>here it is!</a></p></div>}
              {failure && <div className="message"><p>Something didn't work. Please try again.</p></div>}
            </Response>
          </div>
        </Fade>
      </Hero>
      <Media />
      <Blog>
        <SectionTitle>Latest Stories</SectionTitle>
        <Wrapper>
          <Content>
            {postEdges.map(post => (
              <Article
                title={post.node.frontmatter.title}
                date={post.node.frontmatter.date}
                excerpt={post.node.excerpt}
                timeToRead={post.node.timeToRead}
                slug={post.node.fields.slug}
                categories={post.node.frontmatter.categories}
                image={post.node.frontmatter.image}
                key={post.node.fields.slug}
              />
            ))}
          </Content>
        </Wrapper>
      </Blog>
    </Layout>
  )
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const IndexQuery = graphql`
  query IndexQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MM/DD/YYYY")
            categories
            image
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`

